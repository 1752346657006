import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

function NavBar() {

    const [open, setOpen] = useState(false);

    return (
        //     bg-white  
        <nav
            className="flex-row navbar navbar-nav navbar-dark navbar-expand-xl gradient position-static">

            <div className="w-75 ml-5">
                <Link className="navbar-brand" id="main-logo" to="">
                    Edwin Samaniego Portfolio
                </Link>
            </div>
            <div className="w-auto ml-auto">

                <Button
                    data-toggle="collapse" className="navbar-toggler" data-target="nav-bar-collapse"
                    onClick={() => setOpen(!open)} aria-controls="collapse" aria-expanded={open}>
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </Button>

                <Collapse in={open}>
                    <div className="collapse navbar-collapse" id="nav-bar-collapse">
                        <ul className="navbar-nav" onClick={() => setOpen(!open)}>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active" to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active is-active" to='projects'>
                                    Projects
                                </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active is-active" to='resume'>
                                    Resume
                                </Link>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                            <Link className="nav-link active is-active" to="contact">
                                Contact
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Link className="nav-link active is-active">
                                Color
                            </Link>
                        </li> */}
                        </ul>
                    </div>
                </Collapse>
            </div>
        </nav>
    );
}

export default NavBar;