import React from "react";

function AboutPage(props) {
    return (
        <section className="portfolio-block block-intro">
            <div className="container changeText">
                <h1 className="headers">
                    Hello, Edwin Samaniego here!
                </h1>
                <p>
                    I am a full stack software developer based in the NYC, NJ area.
                    <br />
                    I am a graduate from CUNY New York City College of Technology.
                    <br />
                    <br />
                    <span style={{ textDecoration: 'underline' }}>Some of my hobbies include:</span>
                    <ul className="dev-list">
                        <li>Web Development</li>
                        <li>App Development</li>
                        <li>Game Development</li>
                    </ul>
                    <br />
                    <span style={{ textDecoration: 'underline' }}>Fun facts about myself:</span>
                    <ul className="dev-list">
                        <li>I am genuinely enjoy learning in my spare time.</li>
                        <li>I have not explored many foods, so I am pretty open to exploring new foods.</li>
                    </ul>
                </p>
            </div>
        </section >
    )
}

export default AboutPage;