import React from "react";
import YoutubeEmbed from "./Video";
import Button from 'react-bootstrap/Button';

class FeaturedProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            show: false,
        };
    }

    toggle = () => {
        const {show} = this.state;
        this.setState({show: !show});
    }

    render() {
        return (
        <div
            className="col main-projects">
            <YoutubeEmbed embedId={this.props.embedId} />
            <br/>
            <div className="card border-0 project-body card-img">
                <div className="card-body">
                    <h2 className="card-title">
                        {this.props.projectTitle}
                    </h2>
                    <h5 className="defaultText">
                        {this.props.projectSubTitle}
                    </h5>
                    <h6 className="defaultText">
                        {this.props.projectRole}
                    </h6>
                    <p className="text-muted card-text">
                        {this.state.show && this.props.projectDescriptionText}
                    </p>
                    <div class="card-button-group">
                        <Button
                            data-toggle="collapse" className="m-1 btn btn-lg btn-primary gradient"
                            onClick={() => this.toggle()}
                            aria-controls="collapse"
                            type="button">
                            {this.state.show ? "Hide Description" : "Show Description"}
                        </Button>
                        <Button
                            className="btn btn-lg btn-primary gradient m-1"
                            type="button">
                        <a
                            className="card-project-button"
                            href={this.props.sourceCodeLink}
                            target={"_blank"}
                            rel="noopener noreferrer">
                            Source Code
                        </a>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default FeaturedProject;