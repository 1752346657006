import React from "react";
import Button from 'react-bootstrap/Button';

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    toggle = () => {
        const {show} = this.state;
        this.setState({show: !show});
    }

    changeImageSize = (e) => {
        e.target.style.width = '100%';
        e.target.style.height = "250px";
    }

    resetImageSize = (e)  => {
        e.target.style.width = '85%';
        e.target.style.height = "250px";
    }

    render() {
        return (
        <div className="col main-projects">
            <div className="card-img-top image-border"> 
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.demoLocation}>
                    <img
                        width="85%"
                        height="250px"
                        src={this.props.imSrcLocation}
                        alt="Img of the project"
                        onMouseOver={this.changeImageSize}
                        onMouseLeave={this.resetImageSize}
                    />
                </a>
            </div>
            <br/>
            <div className="card border-0 project-body">
                <div className="card-body">
                    <h1 className="card-title">
                        {this.props.projectTitle}
                    </h1>
                    <h5 className="">
                        Built Using: {this.props.projectSubTitle}
                    </h5>
                    <h6 className="">
                        Role: {this.props.projectRole}
                    </h6>
                    <p className="text-muted card-text">
                        {this.state.show && this.props.projectDescriptionText}
                    </p>
                    <div class="card-button-group">
                        <Button
                            data-toggle="collapse" className="btn btn-lg btn-primary gradient m-1"
                            onClick={() => this.toggle()}
                            aria-controls="collapse"
                            type="button">
                            {this.state.show ? "Hide Description" : "Show Description"}
                        </Button>
                        <Button
                            className="btn btn-lg btn-primary gradient m-1"
                            type="button">
                        <a
                            className="card-project-button"
                            href={this.props.sourceCodeLink}
                            target={"_blank"}
                            rel="noopener noreferrer">
                            {this.props.sourceCodeTxt || "Source Code"}
                        </a>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Project;