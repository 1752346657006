import React from 'react';
import Button from 'react-bootstrap/Button';
import { findCoinbaseFees, findCoinbaseProFees, findGeminiFees, findGeminiActiveTraderFees } from './feeFinder';

class CryptoCalculatorApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCoins: 0,
            taxRate: 0,
            valueBought: 0,
            valueSold: 0,
            percent: 0.0149,
            feeStruct: 1,
            totalPaid: 0,
            totalSold: 0,
            estFees: 0,
            estTaxes: 0,
            estGainlossAfterTax: 0,
            estGainlossBeforeTax: 0,
            revealG: false,
            revealCb: false,
            canCalculate: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    changePercentCoinBase = () => {
        if (document.getElementById("bank").checked) this.setState({ percent: 0.0149 });
        else if (document.getElementById("wallet").checked) this.setState({ percent: 0.0149 });
        else if (document.getElementById("card").checked) this.setState({ percent: 0.0399 });
    }

    changePercentGemini = () => {
        if (document.getElementById("bank").checked) this.setState({ percent: 0 })
        else if (document.getElementById("card").checked) this.setState({ percent: 0.0349 })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'number' ? target.value : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    updateFees = () => {
        if (document.getElementById('coinbase').checked) {
            this.setState({
                feeStruct: 1,
                percent: .0149,
                revealCb: true,
                revealG: false,
                canCalculate: true
            });
        }
        else if (document.getElementById('coinbasePro').checked) {
            this.setState({
                feeStruct: 2,
                percent: .0149,
                revealCb: false,
                revealG: false,
                canCalculate: true
            });
        }
        else if (document.getElementById('gemini').checked) {
            this.setState({
                feeStruct: 3,
                percent: 0.0,
                revealCb: false,
                revealG: true,
                canCalculate: true
            });
        }
        else if (document.getElementById('geminiActiveTrader').checked) {
            this.setState({
                feeStruct: 4,
                percent: 0.0,
                revealCb: false,
                revealG: false,
                canCalculate: true
            })
        }
        else {
            this.setState({
                feeStruct: 1,
                percent: .0149,
                revealCb: false,
                revealG: false,
                canCalculate: true
            });
        }
    }

    feeFinder = (price) => {
        if (this.state.feeStruct === 1) return findCoinbaseFees(price, this.state.percent);
        else if (this.state.feeStruct === 2) return findCoinbaseProFees(price);
        else if (this.state.feeStruct === 3) return findGeminiFees(price, this.state.percent);
        else return findGeminiActiveTraderFees(price)
    }

    calculate = () => {
        if (
            this.state.canCalculate !== true
            && this.state.totalCoins !== 0
            && this.state.totalPaid !== 0
            && this.state.totalSold !== 0
        ) {
            alert("Must select an option to calculate!");
        }
        else {
            let totalCoins = this.state.totalCoins;
            let bought = this.state.valueBought;
            let sold = this.state.valueSold;
            let taxRate = this.state.taxRate / 100;
            // console.log("taxRate: " + taxRate)
            let purchasePrice = Math.round(((totalCoins * bought) + Number.EPSILON) * 100) / 100;
            // console.log("Purchase price: " + purchasePrice)
            let soldPrice = Math.round(((totalCoins * sold) + Number.EPSILON) * 100) / 100;
            let estimatedProfit = soldPrice - purchasePrice;
            let feesPaidBuy = this.feeFinder(purchasePrice);
            // console.log("feesPaidBuy: " + feesPaidBuy);
            let feesPaidSell = this.feeFinder(soldPrice);
            let totalPurchase = purchasePrice + feesPaidBuy; // total purchase price
            let totalSell = soldPrice - feesPaidSell;
            let taxes = estimatedProfit * taxRate;
            let projectedProfit = estimatedProfit - taxes - feesPaidSell;
            this.setState({
                totalPaid: this.formatter.format(totalPurchase),
                totalSold: this.formatter.format(totalSell),
                estFees: this.formatter.format(feesPaidSell),
                estTaxes: this.formatter.format(taxes),
                estGainlossBeforeTax: this.formatter.format(estimatedProfit),
                estGainlossAfterTax: this.formatter.format(projectedProfit),
                calculated: true
            });
        }
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    render() {
        return (
            <div id="container">
                <section className="portfolio-block block-intro">
                    <h1 style={{ color: 'red' }}>This is based off the fee structure in 2021.</h1>
                    <h1 style={{ color: 'red', fontWeight: 'bold', fontStyle: 'italic' }} >This is no longer accurate</h1>
                    <h1 >Select a platform</h1>
                    <h4 >Only Supports US Exchanges</h4>
                    <div className="group">
                        <div className='row'>
                            {/* <!-- Left Section of Web App--> */}

                            {/* <!-- Beginning of Exchange List --> */}
                            <div className='col' id="exchanges" onChange={() => this.updateFees()}>
                                <ul className='list '>
                                    <li className="in-row">
                                        <h3 >
                                            Choose an exchange
                                        </h3>
                                    </li>
                                    <tr>
                                        <td>
                                            <input className='input-style' type="radio" id="coinbase" name="exchange" />
                                        </td>
                                        <td>
                                            <p className="in-label">Coinbase</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input className='input-style' type="radio" id="coinbasePro" name="exchange" />

                                        </td>
                                        <td>
                                            <p className="in-label">CoinbasePro</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input className='input-style' type="radio" id="gemini" name="exchange" />
                                        </td>
                                        <td>
                                            <p className="in-label">Gemini</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input className='input-style' type="radio" id="geminiActiveTrader" name="exchange" />

                                        </td>
                                        <td>
                                            <p className="in-label">Gemini Active Trader</p>
                                        </td>
                                    </tr>
                                </ul>
                                {
                                    this.state.revealCb
                                    &&
                                    <div onChange={() => this.changePercentCoinBase()}>
                                        <hr />
                                        <h3>Payment Options</h3>
                                        <ul>
                                            <input className='input-style' type="radio" name="payment" id="bank" />
                                            <p className="in-label"> US Bank Account </p>
                                        </ul>
                                        <ul>
                                            <input className='input-style' type="radio" name="payment" id="wallet" />
                                            <p className="in-label"> Coinbase USD Wallet </p>
                                        </ul>
                                        <ul>
                                            <input className='input-style' type="radio" name="payment" id="card" />
                                            <p className="in-label"> Debit Card </p>
                                        </ul>
                                    </div>
                                }
                                {
                                    this.state.revealG
                                    &&
                                    <div onChange={() => this.changePercentGemini()}>
                                        <hr />
                                        <h3>
                                            Payment Options
                                        </h3>
                                        <ul>
                                            <input className='input-style' type="radio" name="payment" id="bank" />
                                            US Bank Account
                                        </ul>
                                        <ul>
                                            <input className='input-style' type="radio" name="payment" id="card" />
                                            Debit Card
                                        </ul>
                                    </div>
                                }
                            </div> {/* <!-- End of Left Section of Web App --> */}
                            <div className="col exchanges"> {/* <!-- Start of Right Section of Web App --> */}
                                <ul className='list'>
                                    <li className="in-row">
                                        <h3>
                                            Enter the following :
                                        </h3>
                                    </li>
                                    <li className="in-row">
                                        <tr>
                                            <td
                                                class={'c-f'}
                                            >
                                                <p className="in-label">Total Coins </p>
                                            </td>
                                            <td
                                                class={'c-in'}
                                            >
                                                <input
                                                    type="number"
                                                    id="coins"
                                                    name="totalCoins" min="1"
                                                    className='input-style'
                                                    value={this.state.totalCoins}
                                                    onChange={this.handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </li>
                                    <li className="in-row">
                                        <tr>
                                            <td>
                                                <p className="in-label">Price Bought </p>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="valueBought"
                                                    id="bought" min="0"
                                                    className='input-style'
                                                    value={this.state.valueBought}
                                                    onChange={(this.handleChange)}
                                                />
                                            </td>
                                        </tr>
                                    </li>
                                    <li className="in-row">
                                        <tr>
                                            <td>
                                                <p className="in-label">
                                                    Price Sold
                                                </p>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name="valueSold"
                                                    id="sold" min="0"
                                                    className='input-style'
                                                    value={this.state.valueSold}
                                                    onChange={this.handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </li>
                                    <li className="in-row">
                                        <tr>
                                            <td>
                                                <p className="in-label">
                                                    Tax Rate
                                                </p>
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    name='taxRate'
                                                    id="taxRate"
                                                    min="1"
                                                    max='100'
                                                    className='input-style'
                                                    value={this.state.taxRate}
                                                    onChange={this.handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </li>
                                    <li className="in-row">
                                        <Button className="btn btn-lg btn-primary gradient" onClick={this.calculate} >
                                            Calculate
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                            <div id="result" className="col">
                                <ul className='list'>
                                    <li className="in-row">
                                        <h3>
                                            Estimated Profit / Loss
                                        </h3>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Est. Total Bought:
                                            <span className="span-label" id="totalPurchase">{this.state.totalPaid}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Est. Total Sold:
                                            <span className="span-label" id="totalSell">
                                                {this.state.totalSold}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Profit Before:
                                            <span className="span-label" id="estProfit">
                                                {this.state.estGainlossBeforeTax}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Fees:
                                            <span className="span-label" id="fees">
                                                {this.state.estFees}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Taxes:
                                            <span className="span-label" id="tax">
                                                {this.state.estTaxes}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="in-row">
                                        <p className="in-label">
                                            Gain / Loss {<br />}After Taxes & Fees:
                                            <span className="span-label" id="projProfit">
                                                {this.state.estGainlossAfterTax}
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div> {/* <!-- End of Row--> */}
                    </div>
                </section>
            </div >
        );
    }
}
export default CryptoCalculatorApp;